.header-title[data-v-7a63e850] {
  font-weight: bold;
}
.error-con[data-v-7a63e850] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.error-con span[data-v-7a63e850] {
    padding: 5px 0;
}
