.payslip-upload[data-v-74814f58] {
  width: 100%;
  height: 100%;
  background: #fff;
}
.payslip-upload .el-steps[data-v-74814f58] {
    padding: 50px 50px 30px;
}
.payslip-upload .upload-step[data-v-74814f58] {
    text-align: center;
    flex: 1;
    background: #fff;
    padding-top: 140px;
    padding-bottom: 50px;
}
.payslip-upload .upload-step .downloadTem[data-v-74814f58] {
      width: 360px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      color: #a9a9ac;
}
.payslip-upload .upload-step .downloadTem span[data-v-74814f58] {
        color: #ff9362;
}
.payslip-upload .upload-step .month-picker[data-v-74814f58] {
      width: 360px;
}
.payslip-upload .upload-step .month-picker[data-v-74814f58] .el-input__prefix {
        width: 25px;
}
.payslip-upload .check-payslip-container[data-v-74814f58] {
    background: #fff;
    height: calc(100% - 137px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.payslip-upload .footer-container[data-v-74814f58] {
    border-top: 1px solid #ededed;
    padding: 20px;
    text-align: right;
}
.payslip-upload .footer-container .error-container[data-v-74814f58] {
      display: flex;
      flex-direction: column;
}
.payslip-upload .footer-container .error-container .error-msg[data-v-74814f58] {
        color: #ff0000;
}
.payslip-upload .footer-container .error-container .error-msg > span[data-v-74814f58] {
          margin-right: 0.1rem;
}
.payslip-upload .footer-container .tooltip-item[data-v-74814f58] {
      margin: 4px;
}
.payslip-upload .flex-container[data-v-74814f58] {
    display: flex;
    justify-content: space-between;
}
