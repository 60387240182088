.check-payslip[data-v-da997594] {
  padding: 0 50px;
  flex: 1;
  height: calc(100% - 121px - 32px - 40px);
}
.check-payslip .check-payslip-table[data-v-da997594] {
    height: calc(100% - 72px);
}
.check-payslip .check-payslip-table[data-v-da997594] .el-table__header-wrapper .cell {
      white-space: pre-line;
}
.check-payslip .check-payslip-pagination[data-v-da997594] {
    margin: 0 auto;
}
