.send-payslip[data-v-5f83113a] {
  width: 500px;
  margin-top: 140px;
  margin-left: auto;
  margin-right: auto;
}
.send-payslip .title[data-v-5f83113a] {
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.send-payslip .send-form[data-v-5f83113a] {
    margin-top: 20px;
}
