.upload-btn .import-upload[data-v-1b6678d6] {
  margin-top: 10px;
}
.upload-btn .import-upload[data-v-1b6678d6] .el-upload-dragger {
    border-radius: 0;
    border-style: solid;
}
.upload-btn .upload-progress[data-v-1b6678d6] {
  width: 360px;
  margin-top: 7px;
  margin-left: auto;
  margin-right: auto;
}
