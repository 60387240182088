.header[data-v-a628b546] {
  font-weight: 700;
  font-size: 16px;
  color: #303133;
  margin-bottom: 10px;
  text-align: center;
}
.assoc-con[data-v-a628b546] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
.assoc-con li[data-v-a628b546] {
    width: 100%;
    color: #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.assoc-con li span[data-v-a628b546] {
      padding: 5px 0;
      flex: 1;
      text-align: center;
      border-left: 1px solid #ebeef5;
      border-top: 1px solid #ebeef5;
}
p.tip[data-v-a628b546] {
  font-size: 14px;
  line-height: 21px;
  margin-top: 15px;
  color: #ff9362;
  text-align: justify;
}
